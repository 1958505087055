<template>
  <v-layout>
  <v-container fluid>
    <div class="d-flex justify-space-between align-center pa-4">
      <p class="display-1 text--primary">
        {{ $t('labels.label') }}
      </p>
    </div>
    <v-row class="d-flex justify-center align-start align-content-center">
      <v-col cols="7">
        <v-row>
          <v-col cols="12">
            <v-row class="ma-4">
              <v-form
                ref="form"
                v-model="valid"
                style="width: 100%; max-width: 100vw"
                class="d-flex justify-space-around align-center"
              >
                <v-col class="col-6 py-0">
                  <v-autocomplete
                    outlined
                    dense
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    :clearable="true"
                    color="primary"
                    item-color="primary"
                    :items="filteredLabelsEnum"
                    :rules="requireRules"
                    class="required"
                    return-object
                    item-text="text"
                    item-value="id"
                    persistent-hint
                    v-model="labelType"
                    @change="changeLabelType()"
                    @click:clear="clearLabelType()"
                    :loading="loading"
                  >
                    <template v-slot:label>
                      {{ $t('records.titles.labels.labelType') }}
                      <span class="red--text"><strong>*</strong></span>
                    </template>
                    <template v-slot:selection='{ item }'>
                      <v-chip small>
                        {{ item.text | truncate(15) }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="col-6 py-0">
                  <v-autocomplete
                    outlined
                    dense
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    :clearable="true"
                    color="primary"
                    item-color="primary"
                    :items="getPrintFormatList"
                    :rules="requireRules"
                    class="required"
                    item-text="type"
                    persistent-hint
                    v-model="printType"
                    small-chips
                    @change="setLabelAndPrint()"
                    @click:clear="clearPrintType()"
                    :loading="loading"
                    :disabled="!labelType"
                  >
                    <template v-slot:label>
                      {{ $t('records.titles.labels.printType') }}
                      <span class="red--text"><strong>*</strong></span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-form>
            </v-row>
          </v-col>
          <v-col class="col-12">
            <v-window v-model="onboarding">
              <v-overlay
                :value="loadingForm"
                absolute
                color="white"
                :opacity="50"
              >
                <v-progress-circular indeterminate size="64" color="primary" />
              </v-overlay>
              <v-window-item eager
                             v-for="(record, index) in formData"
                             :key="`record-${index}`"
              >
                <v-card :elevation="0" class="ml-3 mr-3">
                  <v-card-text class="py-0">
                    <v-form ref="form1" v-model="valid1[index+1]">
                      <v-row>
                        <v-col class="col-12 py-0">
                          <v-row
                            v-if="
                              formData[index].printType !== '' &&
                              formData[index].printType &&
                              formData[index].labelType &&
                              ![5, 8, 9].includes(formData[index].labelType.id)
                            "
                          >
                            <v-card elevation="0" width="100%">
                              <v-card-title class="neuter--text pa-2 mt-2">
                                {{$t('records.titles.labels.produced')}}
                              </v-card-title>
                              <v-divider></v-divider>
                              <v-card-text
                                class="d-flex flex-wrap justify-left pb-0"
                              >
                                <v-col class="col-6">
                                  <v-text-field
                                    outlined
                                    dense
                                    onkeypress="return (event.charCode !== 34)"
                                    v-on:keyup.86="replaceProducedBy(index)"
                                    v-on:keyup.ctrl.86="
                                      replaceProducedBy(index)
                                    "
                                    :rules="requireRules"
                                    v-model="formData[index].producedBy"
                                  >
                                    <template v-slot:label>
                                      {{ $t("records.titles.labels.producedBy") }}
                                      <span class="red--text"><strong>*</strong></span>
                                    </template>
                                  </v-text-field>
                                </v-col>
                                <v-col class="col-6">
                                  <v-text-field
                                    v-if="
                                      formData[index].printType !== '' &&
                                      formData[index].printType &&
                                      formData[index].labelType &&
                                      ![10,11].includes(formData[index].labelType.id)
                                    "
                                    onkeypress="return (event.charCode !== 34)"
                                    v-on:keyup.86="
                                      replaceDocumentValueProduced(index)
                                    "
                                    v-on:keyup.ctrl.86="
                                      replaceDocumentValueProduced(index)
                                    "
                                    outlined
                                    dense
                                    v-model="
                                      formData[index].documentValueProduced
                                    "
                                    :label="
                                      $t(
                                        'records.titles.labels.documentValueProduced'
                                      )
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col class="col-12 pt-0">
                                  <v-text-field
                                    onkeypress="return (event.charCode !== 34)"
                                    v-on:keyup.86="
                                      replaceAllAddressProduced(index)
                                    "
                                    v-on:keyup.ctrl.86="
                                      replaceAllAddressProduced(index)
                                    "
                                    outlined
                                    dense
                                    :rules="requireRules"
                                    v-model="formData[index].allAddressProduced"
                                  >
                                    <template v-slot:label>
                                      {{ $t("records.titles.labels.allAddressProduced") }}
                                      <span class="red--text"><strong>*</strong></span>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-card-text>
                            </v-card>
                          </v-row>
                          <v-row
                            v-if="
                              formData[index].printType !== '' &&
                              formData[index].printType &&
                              formData[index].labelType &&
                              ![5, 8, 9, 10,11].includes(formData[index].labelType.id)
                            "
                          >
                            <v-card elevation="0" width="100%">
                              <v-card-title class="neuter--text pa-2 mt-2">
                                {{$t('records.titles.labels.distributed') }}
                              </v-card-title>
                              <v-divider></v-divider>
                              <v-card-text
                                class="d-flex flex-wrap justify-left pb-0"
                              >
                                <v-col class="col-6">
                                  <v-text-field
                                    onkeypress="return (event.charCode !== 34)"
                                    v-on:keyup.86="replaceDistributedBy(index)"
                                    v-on:keyup.ctrl.86="
                                      replaceDistributedBy(index)
                                    "
                                    outlined
                                    dense
                                    :rules="requireRules"
                                    v-model="formData[index].distributedBy"
                                  >
                                    <template v-slot:label>
                                      {{ $t("records.titles.labels.distributedBy") }}
                                      <span class="red--text"><strong>*</strong></span>
                                    </template>
                                  </v-text-field>
                                </v-col>
                                <v-col class="col-6">
                                  <v-text-field
                                    onkeypress='return (event.charCode !== 34)'
                                    v-on:keyup.86='
                                      replaceDocumentValueDistributed(index)
                                    '
                                    v-on:keyup.ctrl.86='
                                      replaceDocumentValueDistributed(index)
                                    '
                                    outlined
                                    dense
                                    v-model='
                                      formData[index].documentValueDistributed
                                    '
                                    :label="
                                      $t(
                                        'records.titles.labels.documentValueDistributed'
                                      )
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col class="col-12 pt-0">
                                  <v-text-field
                                    onkeypress="return (event.charCode !== 34)"
                                    v-on:keyup.86="
                                      replaceAllAddressDistributed(index)
                                    "
                                    v-on:keyup.ctrl.86="
                                      replaceAllAddressDistributed(index)
                                    "
                                    outlined
                                    dense
                                    :rules="requireRules"
                                    v-model="
                                      formData[index].allAddressDistributed
                                    "
                                  >
                                    <template v-slot:label>
                                      {{ $t("records.titles.labels.allAddressDistributed") }}
                                      <span class="red--text"><strong>*</strong></span>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-card-text>
                            </v-card>
                          </v-row>
                          <v-row
                            v-if="
                              formData[index].printType !== '' &&
                              formData[index].printType
                            "
                          >
                            <v-card elevation="0" width="100%">
                              <v-card-title class="neuter--text pa-2 mt-2">
                                {{$t('records.titles.labels.additional')}}:
                                <span
                                  class="primary--text pl-2"
                                  v-if="formData[index].productsData[0].product"
                                >{{
                                    formData[index].productsData[0].product
                                      .product_name
                                  }}</span
                                >
                              </v-card-title>
                              <v-divider></v-divider>
                              <v-card-text>
                                <v-row
                                  class="d-flex flex-wrap justify-left"
                                >
                                  <v-col
                                    class="col-6"
                                    v-if="
                                      formData[index].labelType.id !== 3 &&
                                      formData[index].labelType.id !== 4 &&
                                      formData[index].labelType.id !== 6 &&
                                      formData[index].labelType.id !== 5 &&
                                      formData[index].labelType.id !== 8 &&
                                      formData[index].labelType.id !== 9
                                    "
                                  >
                                    <v-text-field
                                      v-if="
                                      formData[index].printType !== '' &&
                                      formData[index].printType &&
                                      formData[index].labelType &&
                                      ![10,11].includes(formData[index].labelType.id)
                                    "
                                      onkeypress="return (event.charCode !== 34)"
                                      v-on:keyup.86="replace"
                                      v-on:keyup.ctrl.86="replace"
                                      outlined
                                      dense
                                      v-model="
                                        formData[index].productsData[0]
                                          .providerCode
                                      "
                                      :label="
                                        $t('records.titles.labels.providerCode')
                                      "
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    class="col-6"
                                    v-if="
                                      formData[index].labelType.id !== 3 &&
                                      formData[index].labelType.id !== 4 &&
                                      formData[index].labelType.id !== 6 &&
                                      formData[index].labelType.id !== 5 &&
                                      formData[index].labelType.id !== 8 &&
                                      formData[index].labelType.id !== 9
                                    "
                                  >
                                  </v-col>
                                  <v-col
                                    class="col-6"
                                    v-if="formData[index].labelType.id !== 3"
                                  >
                                    <v-text-field
                                      ref="netWeight"
                                      outlined
                                      dense
                                      :rules="maxRules(6, formData[index].productsData[0].netWeight).concat(onlyPositiveNumberFormat)"
                                      :label="
                                        $t('records.titles.labels.netWeight')
                                      "
                                      v-model="
                                        formData[index].productsData[0]
                                          .netWeight
                                      "
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col
                                    class="col-6"
                                    v-if="formData[index].labelType.id !== 3"
                                  >
                                    <v-autocomplete
                                      :no-data-text="
                                        $t('common.fields.noDataAvailable')
                                      "
                                      outlined
                                      dense
                                      item-color="primary"
                                      return-object
                                      clearable
                                      :items="allUnits"
                                      item-text="unit_name"
                                      small-chips
                                      item-value="code"
                                      persistent-hint
                                      :disabled="!(formData[index].productsData[0]
                                          .netWeight)"
                                      v-model="
                                        formData[index].productsData[0].unit
                                      "
                                      required
                                      class="required pt-0"
                                      :rules="
                                        formData[index].productsData[0]
                                          .netWeight &&
                                        formData[index].productsData[0]
                                          .netWeight > -1
                                          ? requireRules
                                          : []
                                      "
                                    >
                                      <template v-slot:label>
                                        {{ $t("product.info.unit") }}
                                        <span v-if="formData[index].productsData[0].netWeight &&
                                                    formData[index].productsData[0].netWeight > -1"
                                              class="red--text"
                                        >
                                          <strong>*</strong>
                                        </span>
                                      </template>
                                      <template
                                        v-slot:selection="{
                                          item,
                                          index,
                                          attrs,
                                          select,
                                          selected,
                                        }"
                                      >
                                        <v-chip small v-if="index === 0">
                                          <span>{{ item.unit_name }}</span>
                                        </v-chip>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col
                                    class="col-12 pt-0"
                                    v-if="
                                      (formData[index].labelType.id === 5 ||
                                        formData[index].labelType.id === 8 ||
                                        formData[index].labelType.id === 9) &&
                                      formData[index].process_type !==
                                        'AGRICULTURAL'
                                    "
                                  >
                                    <v-row>
                                      <v-col cols="6">
                                        <v-text-field
                                          onkeypress="return (event.charCode !== 34)"
                                          v-on:keyup.86="replace"
                                          v-on:keyup.ctrl.86="replace"
                                          outlined
                                          dense
                                          v-model="
                                            formData[index].productsData[0].lot
                                          "
                                          :label="$t('product.info.lot')"
                                          required
                                          class="required"
                                          :rules="lotRules"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col
                                    class="col-12 pt-0"
                                    v-if="
                                      (formData[index].labelType.id === 5 ||
                                        formData[index].labelType.id === 8 ||
                                        formData[index].labelType.id === 9) &&
                                      formData[index].process_type ===
                                        'AGRICULTURAL'
                                    "
                                  >
                                    <v-row>
                                      <v-col cols="6">
                                        <v-autocomplete
                                          outlined
                                          dense
                                          :no-data-text="
                                            $t('common.fields.noDataAvailable')
                                          "
                                          color="primary"
                                          item-color="primary"
                                          return-object
                                          clearable
                                          :items="seasons"
                                          :label="$t('season.fields.name')"
                                          item-text="season_name"
                                          small-chips
                                          item-value="id"
                                          persistent-hint
                                          v-model="
                                            formData[index].productsData[0]
                                              .seasson
                                          "
                                        >
                                          <template
                                            v-slot:selection="{
                                              item,
                                              index,
                                              attrs,
                                              select,
                                              selected,
                                            }"
                                          >
                                            <v-chip small v-if="index === 0">
                                              <span>{{
                                                  item.season_name
                                                }}</span>
                                            </v-chip>
                                          </template>
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </v-col>

                                  <v-col
                                    class="col-6"
                                    v-if="
                                      formData[index].labelType.id === 5 ||
                                      formData[index].labelType.id === 8 ||
                                      formData[index].labelType.id === 9
                                    "
                                  >
                                    <v-menu
                                      v-model="refers[index].manufacturingDate"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          onkeypress="return (event.charCode !== 34)"
                                          v-on:keyup.86="replace"
                                          v-on:keyup.ctrl.86="replace"
                                          outlined
                                          dense
                                          clearable
                                          v-model="
                                            formData[index].productsData[0]
                                              .manufacturingDate
                                          "
                                          :label="
                                            $t(
                                              'product.info.manufacturingDate'
                                            )
                                          "
                                          append-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="
                                          formData[index].productsData[0]
                                            .manufacturingDate
                                        "
                                        @input="
                                          refers[
                                            index
                                          ].manufacturingDate = false
                                        "
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>

                                  <v-col class="col-6">
                                    <v-menu
                                      v-model="refers[index].expirationDate"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          onkeypress="return (event.charCode !== 34)"
                                          v-on:keyup.86="replace"
                                          v-on:keyup.ctrl.86="replace"
                                          outlined
                                          dense
                                          clearable
                                          v-model="
                                            formData[index].productsData[0]
                                              .expirationDate
                                          "
                                          :label="
                                            formData[index].labelType.id ===
                                              4 ||
                                            formData[index].labelType.id === 6
                                              ? $t(
                                                  'records.titles.labels.harvestDate'
                                                )
                                              : $t(
                                                  'records.titles.labels.expirationDate'
                                                )
                                          "
                                          append-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        color="primary"
                                        v-model="
                                          formData[index].productsData[0]
                                            .expirationDate
                                        "
                                        @input="
                                          refers[index].expirationDate = false
                                        "
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col
                                    class="col-6"
                                    v-if="
                                      formData[index].labelType.id !== 5 &&
                                      formData[index].labelType.id !== 8 &&
                                      formData[index].labelType.id !== 9
                                    "
                                  >
                                    <v-menu
                                      v-model="refers[index].packingDate"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          onkeypress="return (event.charCode !== 34)"
                                          v-on:keyup.86="replace"
                                          v-on:keyup.ctrl.86="replace"
                                          outlined
                                          dense
                                          clearable
                                          v-model="
                                            formData[index].productsData[0]
                                              .packingDate
                                          "
                                          :label="
                                            $t(
                                              'records.titles.labels.packingDate'
                                            )
                                          "
                                          append-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        color="primary"
                                        v-model="
                                          formData[index].productsData[0]
                                            .packingDate
                                        "
                                        @input="
                                          refers[index].packingDate = false
                                        "
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col
                                    class="col-12"
                                    v-if="formData[index].labelType.id !== 3"
                                  >
                                    <v-text-field
                                      onkeypress="return (event.charCode !== 34)"
                                      v-on:keyup.86="replace"
                                      v-on:keyup.ctrl.86="replace"
                                      outlined
                                      dense
                                      v-model="
                                        formData[index].productsData[0]
                                          .additionalInformation
                                      "
                                      :label="
                                        $t(
                                          'records.titles.labels.additionalInformation'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions
                    class="justify-space-between pa-6"
                    v-if="labelType && printType"
                  >
                    <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="prev">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-item-group
                      v-model="onboarding"
                      class="text-center"
                      mandatory
                    >
                      <v-item
                        v-for="n in formData.length"
                        :key="`btn-${n}`"
                        v-slot="{ active, toggle }"
                      >
                        <v-btn small elevation="0" color="primary" :input-value="active" icon @click="toggle">
                          <v-icon>mdi-record</v-icon>
                        </v-btn>
                      </v-item>
                    </v-item-group>
                    <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="next">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <v-card elevation="0" width="100%">
          <v-card-title class="neuter--text  pb-1 pt-3 pl-1">
            {{
              $t('records.titles.labels.exemplo')
            }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            :class="
              labelType && ![8, 9].includes(labelType.id)
                ? 'd-flex flex-wrap justify-left  pb-0 '
                : 'd-flex flex-wrap justify-center pb-0 horizontalImage'
            "
          >
            <v-img :src="getLabelTemplate" contain></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      class="ml-3 mt-1"
      :value="!ready"
      absolute
      color="white"
      :opacity="50"
    >
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </v-container>
  <v-footer absolute class="pa-6">
    <v-spacer></v-spacer>
    <v-btn small text color="neuter" @click="printCancel()">
      {{ $t('common.buttons.cancel') }}
    </v-btn>
    <v-btn
      small elevation="0" color="primary" class="t-bw-primary--text ml-3 mr-4"
      :disabled="!valid || !isValid || loadingForm"
      :loading="loadingForm"
      @click="generate()"
    >
      <template v-slot:loader>
        <v-progress-circular
          style="position: absolute; margin-left: -3px; height: 15px"
          color="secondary accent-4"
          indeterminate
          rounded
        />
      </template>
      {{ $t('common.buttons.generate') }}
    </v-btn>
  </v-footer>
  </v-layout>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import _ from 'lodash'
  import moment from 'moment'
  import LanguageService from '@/services/LanguajeService'
  import StorageService from "@/services/StorageService";

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    data: () => ({
      e1: 0,
      ready: false,
      profile: null,
      valid: false,
      valid1: {},
      labelTemplate: '',
      onboarding: 0,
      dialog: false,
      editedIndex: -1,
      refers: [],
      modelRefers: {
        packingDate: '',
        expirationDate: '',
      },
      date: '',
      formData: [],
      formDataCopy: [],
      modelProduct: [],
      recordData: [],
      labelType: null,
      printType: null,
      defaultItem: {},
      showDialog: false,
      title: 'Información',
      dialogText: 'Añada un texto a este dialogo',
      max: 40,
      loadingForm: false,
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 15,
          sortBy: 'id',
          direction: 'ASC',
        }
      }
    }),

    computed: {
      filteredLabelsEnum() {
        return this.labelsEnum.filter(label =>
          label.company.includes(0) || label.company.includes(PROFILE.company_id,)
        );
      },
      recordSelected: {
        get() {
          return this.$store.getters['records/record']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD', val)
        },
      },
      ...mapGetters({
        recordsForPrint: 'records/recordsForPrint',
        labelsEnum: 'labels/labelsEnum',
        requireAndMaxRules: 'general/requireAndMaxRules',
        requireRules: 'general/requireRules',
        maxRules: 'general/maxRules',
        onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat',
        allCompanies: 'company/allCompanies',
        allUnits: 'units/allObjectUnits',
        loading: 'units/loading',
        loadingSeasson: 'season/loading',
        seasons: 'season/seasons',
        products: 'product/products',
        lotRules: 'general/lotRules',
        storage: 'storage/allStoragesByCompany',
      }),
      config: {
        get() {
          return this.$store.getters['general/config']
        },
        set(val) {
          this.$store.commit('general/SET_CONFIG', val)
        },
      },
      isValid() {
          const valid = _.filter(this.valid1, (o) => {
            return o === false
          })

          return !(valid.length > 0)
      },
      decimalFormat() {
        return this.config.company.traceability.structure.number.afterPoint !==
          null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
      },
      getPrintFormatList() {
        return this.labelType
          ? _.find(this.labelsEnum, label =>
          label.id === this.labelType.id &&
          (label.company.includes(0) || label.company.includes(PROFILE.company_id))
        )?.types || []
          : [];
      },
      getLabelTemplate() {
        return this.printType
          ? require('@/assets/img/LabelsTemplate/label-' +
              this.labelType.id +
              this.printType +
              '.jpeg')
          : require('@/assets/img/LabelsTemplate/defaultLabelTemplate.png')
      },
      computedPackingDate: {
        get() {
          return index =>
            this.formData[this.e1].productsData[index].packingDate
              ? moment(
                  this.formData[this.e1].productsData[index].packingDate
                ).format('DD / MM / YYYY')
              : ''
        },
        set() {
          return moment(new Date().toISOString().substr(0, 10)).format(
            'DD / MM / YYYY'
          )
        },
      },
      computedExpirationDate: {
        get() {
          return index =>
            this.formData[this.e1].productsData[index].expirationDate
              ? moment(
                  this.formData[this.e1].productsData[index].expirationDate
                ).format('DD / MM / YYYY')
              : ''
        },
        set() {
          return moment(new Date().toISOString().substr(0, 10)).format(
            'DD / MM / YYYY'
          )
        },
      },
    },

    async created() {
      this.config = JSON.parse(localStorage.getItem('config'))
      this.restoreModels()

      if (this.recordsForPrint && this.recordsForPrint.length > 0) {
        this.recordData = [...this.recordsForPrint];
        _.forEach(this.recordsForPrint, object => {
          this.generateModels();
        });
      } else {
        this.recordData = [Object.assign({}, this.recordSelected)];
        this.generateModels();
      }

      await this.initialize()
    },

    methods: {
      ...mapActions({
        fetchAllUnits: 'units/fetchAllUnits',
        fetchListSeasons: 'season/fetchListSeasons',
        fetchListProduct: 'product/fetchListProductByRecord',
        fetchCompany: "company/fetchCompany",
      }),
      generateModels() {
        this.formData.push({
          labelType: '',
          printType: '',
          producedBy: '',
          allAddressProduced: '',
          documentValueProduced: '',
          distributedBy: '',
          allAddressDistributed: '',
          documentValueDistributed: '',
          structures: {},
          location_origin: null,
          location_destination: null,
          productsData: [],
        })

        this.modelProduct.push({
          providerCode: '',
          seasson: null,
          manufacturingDate: '',
          product: null,
          product_value: null,
          unit: null,
          netWeight: null,
          lot: null,
          additionalInformation: '',
          packingDate: '',
          expirationDate: '',
          structureLoteSafra: null,
        })
      },
      restoreModels() {
        this.formData = []
        this.modelProduct = []
      },
      async initialize() {
        this.ready = false
        this.profile = JSON.parse(localStorage.getItem('profile'))
        // PASAR IDS DE RECORDS SELECCIONADOS
        let record_ids = [];
        if (this.recordsForPrint && this.recordsForPrint.length > 0) {
          record_ids = _.map(this.recordsForPrint, (d) => {
                return d.id
              })
        } else {
          record_ids = [this.recordSelected.id];
        }
        const filters = {
          record_ids,
          language: LanguageService.getLenguajeName()
        }
        await this.fetchListProduct([filters, this.$toast])
        await this.fetchListSeasons([this.defaultFilters, this.$toast])
        await this.fetchAllUnits(this.$toast).finally(() => {
          _.forEach(this.recordData, (object, index) => {
            this.setData(object.process.processType, index)
          })
        })
        await this.fetchCompany([this.profile, this.$toast])

        _.forEach(this.formData, formDataObject => {
          let formDataObjectCopy = _.cloneDeep(formDataObject)
          formDataObjectCopy.productsData = null
          if (formDataObject.productsData.length > 1) {
            _.forEach(formDataObject.productsData, productsDataObject => {
              formDataObjectCopy.productsData = [productsDataObject]
              this.formDataCopy.push(_.cloneDeep(formDataObjectCopy))
            })
          } else {
            formDataObjectCopy.productsData = [formDataObject.productsData[0]]
            this.formDataCopy.push(_.cloneDeep(formDataObjectCopy))
          }
        })
        this.formData = _.cloneDeep(this.formDataCopy)
        _.forEach(this.formData, (form, index) => {
          this.refers.push(_.cloneDeep(this.modelRefers))
        })

        this.ready = true
      },
      async getLocationsData(){
        await _.forEach(this.recordData, async (object, index) => {
          const record = Object.assign({}, object)

          let origin = null;
          await StorageService.getById(record.sdi_origin.id).then((res) => {
            origin = res.data
          }).catch(e => console.log)

          let destination = null;
          await StorageService.getById(record.sdi_destination.id).then((res) => {
            destination = res.data
          }).catch(e => console.log)

          await this.updateFromLocation(origin, destination, object)
        } );
      },
      async updateFromLocation(origin, destination, record) {
        let filterRecords = _.filter(this.formData,  function(r) {
          return r.productsData[0].id === record.id
        });
        _.forEach(filterRecords, (object, index) => {

          let originName = null;
          let destinationName = null;
          if (object.labelType.id === 10 || object.labelType.id === 11) {
            originName = record.sdi_origin.control_label
            destinationName = record.sdi_destination.control_label
          } else {
            originName = record.sdi_origin.primaryLocation.name
            destinationName = record.sdi_destination.primaryLocation.name
          }
          // Address
          object.allAddressProduced =
            this.checkIfExist(
              origin.address1,
              true,
              ', '
            ) +
            this.checkIfExist(
              origin.city,
              true,
              ', '
            ) +
            this.checkIfExist(
              origin.state,
              true,
              ', '
            ) +
            this.checkIfExist(origin.country, false)
          object.allAddressDistributed =
            this.checkIfExist(
              destination.address1,
              true,
              ', '
            ) +
            this.checkIfExist(
              destination.city,
              true,
              ', '
            ) +
            this.checkIfExist(
              destination.state,
              true,
              ', '
            ) +
            this.checkIfExist(
              destination.country,
              false
            )

          object.producedBy = originName
          object.distributedBy = destinationName
          object.distributedByCountry = destination.country

          object.documentValueProduced =
            this.checkIfExist(
              record.sdi_destination.primaryLocation.document_key,
              true,
              ': '
            ) +
            this.checkIfExist(
              record.sdi_origin.primaryLocation.document_value,
              false
            )
          this.formData[index].documentValueDistributed =
            this.checkIfExist(
              record.sdi_destination.primaryLocation.document_key,
              true,
              ': '
            ) +
            this.checkIfExist(
              record.sdi_destination.primaryLocation.document_value,
              false
            )
          object.location_origin =
            record.sdi_origin.primaryLocation.id
          object.location_destination =
            record.sdi_destination.primaryLocation.id
          object.sdi_origin = record.sdi_origin.id
          object.sdi_destination = record.sdi_destination.id
          object.sdi_origin_name = record.sdi_origin.control_label
          object.sdi_origin_lat = record.sdi_origin.latitude
          object.sdi_origin_long = record.sdi_origin.longitude
          object.sdi_destination_name = record.sdi_destination.control_label
          object.sdi_destination_lat = record.sdi_destination.latitude
          object.sdi_destination_long = record.sdi_destination.longitude
          object.primary_origin_name = record.sdi_origin.primaryLocation.name
          object.primary_destination_name = record.sdi_destination.primaryLocation.name
          object.process_type = record.process.processType
          object.id = record.id
        })
      },
      nextLabel() {
        this.e1 = this.e1 + 1
      },
      next() {
        this.onboarding =
          this.onboarding + 1 === this.formData.length ? 0 : this.onboarding + 1
      },
      prev() {
        this.onboarding =
          this.onboarding - 1 < 0
            ? this.formData.length - 1
            : this.onboarding - 1
      },
      checkIfExist(target, continueTrue, separator) {
        target = target ? target : ''
        continueTrue = continueTrue && target !== '' ? separator : ''
        return target + continueTrue
      },
      generate() {
        let data = []

        _.forEach(this.formData, (form, root) => {
          _.forEach(form.productsData, product => {
            data.push({
              ...product,
              form: _.omit(this.formData[root], ['productsData']),
            })
          })
        })

        this.$store.commit(
          'general/LABEL_DATA',
          _.cloneDeep({
            labelType: data[0].form.labelType,
            printType: data[0].form.printType,
            productsData: data,
          })
        )
        this.$router.push({ path: '/printRecordLabel/recordsLabel' })
      },
      async setLabelAndPrint() {
        this.loadingForm = true
        _.forEach(this.formData, form => {
          form.labelType = this.labelType
          form.printType = this.printType
        })
        await this.getLocationsData();
        this.loadingForm = false
        setTimeout(() => {
          this.$refs.form1[0].validate();
        }, 300);

      },
      changeLabelType() {
        this.printType = ''
        _.forEach(this.formData, form => {
          form.printType = ''
        })
        this.$refs.form.resetValidation()
      },
      clearPrintType() {
        _.forEach(this.formData, form => {
          form.printType = ''
        })
      },
      clearLabelType() {
        this.labelType = ''
        this.printType = ''
        this.$refs.form.resetValidation()
      },
      printCancel() {
        this.$router.push({ path: '/records' })
        this.restoreModels()
        this.$store.commit('records/SET_ORIGINAL_STATE_FOR_PRINT_RECORDS')
        this.$store.commit('records/SET_ORIGINAL_STATE_RECORD')
        this.$store.commit('labels/SET_ORIGINAL_STATE_LABELS')
      },
      setData(processType, index) {
        if (processType === 'AGRICULTURAL') {
          this.formData[index].productsData.push(this.modelProduct[index])
          this.refers[index] = _.cloneDeep(this.modelRefers)
          _.forEach(
            this.recordData[index].recordStructureValuesOrganized.structures,
            structure => {
              if (structure.structure.structureCode === 'S') {
                this.formData[index].productsData[0].structureLoteSafra =
                  structure
                this.formData[index].productsData[0].seasson = _.find(
                  this.seasons,
                  {
                    season_name: structure.recordStructureValue.record_value[0],
                  }
                )
                this.formData[index].productsData[0].seasson_value =
                  structure.recordStructureValue.record_value[0]
              }
              if (structure.structure.structureCode === 'P') {
                this.formData[index].productsData[0].product = _.find(
                  this.products,
                  {
                    product_name:
                      structure.recordStructureValue.record_value[0],
                  }
                )
                this.$set(this.formData[index].productsData[0], 'product_value', structure.recordStructureValue.record_value[0])
              }
              this.formData[index].productsData[0].id = this.recordData[index].id
            }
          )
        } else if (processType === 'SHIPPING' || processType === 'RECEIPT') {
          const group = _.find(
            this.recordData[index].recordStructureValuesOrganized.groups,
            group => {
              return group.groupCode === 'REEN'
            }
          )
          this.setStructureData(group, 'P', 'L', 'MU', 'C', index)
        } else if (processType === 'PROCESSING') {
          const group = _.find(
            this.recordData[index].recordStructureValuesOrganized.groups,
            group => {
              return group.groupCode === 'GO'
            }
          )
          this.setStructureData(group, 'PO', 'LO', 'MUO', 'CO', index)
        }
      },
      setStructureData(group, productCode, lotCode, MUCode, cantCode, index) {
        _.forEach(group.rows, row => {
          this.formData[index].productsData.push(
            _.cloneDeep(this.modelProduct[index])
          )
          this.refers[index] = _.cloneDeep(this.modelRefers)
          _.forEach(row.values, structure => {
            if (structure.structure.structureCode === productCode) {
              const productObject = _.find(this.products, {
                product_name: structure.recordStructureValue.record_value[0],
              })
              this.formData[index].productsData[row.groupRow - 1]['product'] =
                productObject
              this.formData[index].productsData[
                row.groupRow - 1
              ].product_value = structure.recordStructureValue.record_value[0]
            }
            if (structure.structure.structureCode === lotCode) {
              this.formData[index].productsData[
                row.groupRow - 1
              ].structureLoteSafra = structure
              this.formData[index].productsData[row.groupRow - 1]['lot'] =
                structure.recordStructureValue.record_value[0]
            }
            if (structure.structure.structureCode === MUCode) {
              const unitObject = _.find(this.allUnits, {
                unit_name: structure.recordStructureValue.record_value[0],
              })
              if (unitObject) {
                this.formData[index].productsData[row.groupRow - 1]['unit'] =
                  unitObject
              } else {
                this.formData[index].productsData[row.groupRow - 1]['unit'] = {
                  code: '',
                }
                this.formData[index].productsData[row.groupRow - 1][
                  'unit'
                ].code = structure.recordStructureValue.record_value[0]
              }
            }
            if (structure.structure.structureCode === cantCode) {
              this.formData[index].productsData[row.groupRow - 1]['netWeight'] =
                structure.recordStructureValue.record_value[0]
            }
            this.formData[index].productsData[row.groupRow - 1]['id'] = this.recordData[index].id
          })
        })
      },
      translate(key) {
        return LanguageService.getKey3(key)
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceProducedBy(event, index) {
        this.formData[index].producedBy = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceDocumentValueProduced(event, index) {
        this.formData[index].documentValueProduced = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceAllAddressProduced(event, index) {
        this.formData[index].allAddressProduced = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceDistributedBy(event, index) {
        this.formData[index].distributedBy = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceDocumentValueDistributed(event, index) {
        this.formData[index].documentValueDistributed =
          event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceAllAddressDistributed(event, index) {
        this.formData[index].allAddressDistributed = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
    },
  }
</script>

<style scoped>
  .v-stepper__step {
    padding: 10px !important;
  }

  .horizontalImage {
    width: 50%;
  }
</style>
